import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import classNames from 'classnames';
import { indexStyle } from '../assets/jss/pages/indexStyle';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { graphql, PageRendererProps, Link as GatsbyLink } from 'gatsby';
import { CategoryNode, ProductNode, SeasonalRegionalProductNode, SupplierNode } from '../interfaces/contentful';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { Price } from '../components/Price/Price';
import { title } from '../assets/jss/materialKitBase';
import { useCookies } from 'react-cookie';
import { createCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg2.jpg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const useStyles = makeStyles(indexStyle);

export const indexQuery = graphql`
  query {
    seasonalRegionalProducts: allContentfulSeasonalRegionalProducts(sort: { fields: createdAt }) {
      edges {
        node {
          contentful_id
          title
          slug
          description {
            raw
          }
          beginDate
          attachedRegionalProducts {
            contentful_id
            slug
            node_locale
            title
            price
            unit
            unitValue
            createdAt
            coverImage {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
            }
            suppliedBy {
              name
              slug
              location {
                lat
                lon
              }
            }
            category {
              title
              slug
            }
            notAvailableReason
            invisible
          }
        }
      }
    }
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
    suppliers: allContentfulSuppliers {
      edges {
        node {
          contentful_id
          slug
          name
          regionalproduct {
            contentful_id
          }
        }
      }
    }
    latestProducts: allContentfulRegionalProduct(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          contentful_id
          slug
          node_locale
          title
          price
          unit
          unitValue
          createdAt
          coverImage {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
          suppliedBy {
            name
            slug
            location {
              lat
              lon
            }
          }
          category {
            title
            slug
          }
          notAvailableReason
          invisible
        }
      }
    }
    featuredProducts: allContentfulRegionalProduct(
      filter: { featured: { eq: true } }
      limit: 6
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          slug
          title
          price
          unit
          unitValue
          node_locale
          featured
          coverImage {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
          category {
            title
            slug
          }
          description {
            description
          }
          suppliedBy {
            name
            slug
            location {
              lat
              lon
            }
          }
          notAvailableReason
          invisible
        }
      }
    }
  }
`;

const Index: React.FC<IndexProps> = ({ data }) => {
  const { latestProducts, featuredProducts, categories, suppliers, seasonalRegionalProducts } = data;

  const [cookies] = useCookies(['SeeInvisible']);
  if (!cookies['SeeInvisible']) {
    latestProducts.edges = latestProducts.edges.filter((product) => product.node.invisible !== true);
    featuredProducts.edges = featuredProducts.edges.filter((product) => product.node.invisible !== true);
  }

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: 'main.parallax.title' })}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <div className={classes.brand}>
            <h1>
              <FormattedMessage id="main.parallax.title" defaultMessage="Regional products" />
            </h1>
            <h2 className={classes.title}>
              <FormattedMessage
                id="main.parallax.subtitle"
                defaultMessage="Reduce the carbon footprint and shorten the food chain"
              />
            </h2>
          </div>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.main, classes.mainRaised)} style={{ marginBottom: '20px' }}>
        <div className={classes.container}>
          {seasonalRegionalProducts.edges.map(({ node }) => {
            const richtDescription = JSON.parse(node.description.raw);
            const beginDate = new Date(node.beginDate).toLocaleDateString('nl-NL', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });

            return (
              <Paper
                key={node.contentful_id}
                style={{
                  marginBottom: theme.spacing(2),
                  padding: theme.spacing(2),
                  textAlign: 'center',
                  background:
                    'linear-gradient(84deg, rgba(165,214,167,1) 35%, rgba(134,181,114,0.9780505952380952) 92%)',
                }}
              >
                <Typography variant="h4" component="h2" gutterBottom>
                  {node.title}
                </Typography>
                <FormattedMessage id="general.beginsFrom" defaultMessage="Begins from" />: <b>{beginDate}</b>
                {documentToReactComponents(richtDescription)}
                <Grid key={node.contentful_id} container justify="center" spacing={2}>
                  {node.attachedRegionalProducts.map((regionalProductNode) => {
                    return (
                      <Grid item key={regionalProductNode.contentful_id} md={4} sm={6} xs={10}>
                        <Card product>
                          <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                            <GatsbyLink to={`/products/${regionalProductNode.slug}`}>
                              <GatsbyImage
                                alt={regionalProductNode.slug}
                                image={regionalProductNode.coverImage.gatsbyImageData}
                              />
                            </GatsbyLink>
                          </CardHeader>
                          <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                            <h4 className={classes.cardTitle} style={{ minHeight: '30px' }}>
                              <GatsbyLink style={{ fontSize: '1rem' }} to={`/products/${regionalProductNode.slug}`}>
                                {regionalProductNode.title}
                              </GatsbyLink>
                            </h4>
                            <div style={{ paddingTop: '0', paddingBottom: '10px' }}>
                              <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                              <Link component={GatsbyLink} to={`/categories/${regionalProductNode.category.slug}`}>
                                {regionalProductNode.category.title}
                              </Link>
                              <br />
                              <FormattedMessage id="general.suppliedBy" defaultMessage="Supplied by" />:{' '}
                              <Link component={GatsbyLink} to={`/suppliers/${regionalProductNode.suppliedBy.slug}`}>
                                {regionalProductNode.suppliedBy.name}
                              </Link>
                            </div>
                            <Price product={regionalProductNode} />
                            &nbsp;/&nbsp;
                            {`${regionalProductNode.unitValue} ${regionalProductNode.unit}`}
                            <br />
                            <AddToCartButton product={regionalProductNode} quantity={1} onlyIcon={true} />
                          </CardBody>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            );
          })}

          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.featuredProducts" defaultMessage="Featured products" />
          </Typography>

          <Grid container justify="flex-start" spacing={1}>
            {featuredProducts.edges.map(({ node }) => {
              return (
                <Grid item key={node.contentful_id} md={4} sm={4} xs={12}>
                  <Card product>
                    <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                      <GatsbyLink to={`/products/${node.slug}`}>
                        <GatsbyImage alt={node.slug} image={node.coverImage.gatsbyImageData} />
                      </GatsbyLink>
                      <span className={classes.textBySupplier}>{node.suppliedBy.name}</span>
                    </CardHeader>
                    <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                      <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                        <GatsbyLink style={{ fontSize: '1rem' }} to={`/products/${node.slug}`}>
                          {node.title}
                        </GatsbyLink>
                        <br />
                        <span className={classes.textMuted}>{`(${node.unitValue} ${node.unit})`}</span>
                      </h4>
                      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                        <Link component={GatsbyLink} to={`/categories/${node.category.slug}`}>
                          {node.category.title}
                        </Link>
                      </div>
                      <Price product={node} />
                      <br />
                      <AddToCartButton product={node} quantity={1} onlyIcon={true} />
                    </CardBody>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          <br />
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.latestProducts" defaultMessage="Latest products" />
          </Typography>

          <Paper style={{ padding: theme.spacing(2) }}>
            <Grid container justify="flex-start" spacing={1}>
              {latestProducts.edges.map(({ node }) => {
                return (
                  <Grid item key={node.contentful_id} md={2} sm={4} xs={6}>
                    <Card product style={{ overflow: 'hidden' }}>
                      <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                        <GatsbyLink to={`/products/${node.slug}`}>
                          <GatsbyImage alt={node.slug} image={node.coverImage.gatsbyImageData} />
                        </GatsbyLink>
                        <span className={classes.textBySupplier}>{node.suppliedBy.name}</span>
                      </CardHeader>
                      <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                        <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                          <GatsbyLink to={`/products/${node.slug}`}>{node.title}</GatsbyLink>
                          <br />
                          <span className={classes.textMuted}>{`(${node.unitValue} ${node.unit})`}</span>
                        </h4>
                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                          <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                          <Link component={GatsbyLink} to={`/categories/${node.category.slug}`}>
                            {node.category.title}
                          </Link>
                        </div>
                        <Price product={node} />
                        <br />
                        <AddToCartButton product={node} quantity={1} onlyIcon={true} />
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>

          <br />
          <Typography variant="h5" component="h2" gutterBottom>
            <FormattedMessage id="main.suppliers" defaultMessage="Suppliers" />
          </Typography>

          <Grid container justify="flex-start" spacing={1}>
            {suppliers.edges
              .filter(({ node }) => {
                return node.regionalproduct;
              })
              .map(({ node }) => {
                return (
                  <Grid item key={node.contentful_id} md={6} sm={6} xs={12}>
                    <Card color="warning">
                      <GatsbyLink to={`/suppliers/${node.slug}`}>
                        <CardBody className={classes.textCenter}>{node.name}</CardBody>
                      </GatsbyLink>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

export default Index;

interface IndexProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
    suppliers: {
      edges: { node: SupplierNode }[];
    };
    latestProducts: {
      edges: { node: ProductNode }[];
    };
    featuredProducts: {
      edges: { node: ProductNode }[];
    };
    seasonalRegionalProducts: {
      edges: { node: SeasonalRegionalProductNode }[];
    };
  };
}
